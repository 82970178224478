<template>
    <b-overlay :show="isLoading">
        <b-card style="background-color: #fff;">
            <template #header>
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <div class="mt-2">
                            <b-button class="mr-2" :to="{ name: 'CompartmentIndex' }" variant="outline-secondary"><font-awesome-icon icon="angle-left"></font-awesome-icon> Back</b-button>
                            <b-button @click="loadDataProductionLines" variant="primary" v-if="hasCreatePermission">Assign production line</b-button>
                            <b-modal id="production-line-modal" ref="production-line-modal" :title="compartment  ?
                            `Production line assignation - Compartment ${compartment.compartment}` : 'Assignation production line to compartment'" size="xl" centered no-close-on-backdrop no-close-on-esc>
                                <b-row class="mt-2">
                                    <b-col class="col-md-12 col-lg-12">
                                        <b-table-simple ref="compartmentTable" responsive bordered striped>
                                            <b-thead>
                                                <b-tr>
                                                    <b-th colspan="3" class="text-center">Compartment {{ compartment.compartment }} {{ compartment.surfaceName }}</b-th>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th class="text-center">Surface</b-th>
                                                    <b-th class="text-center">Rows</b-th>
                                                    <b-th class="text-center">Occupancy rate</b-th>
                                                </b-tr>
                                            </b-thead>
                                            <b-tbody>
                                                <b-tr>
                                                    <b-td style="width: 33.3%;">
                                                        <strong>Net surface</strong> {{ compartment.netSurface }} mts² <br>
                                                        <strong>Rows:</strong> {{ compartment.numberOfRows }} <br>
                                                       <strong>Row net surface:</strong> {{ Number(compartment.rowSurface).toFixed(2) }} mts²
                                                    </b-td>
                                                    <b-td style="width: 33.3%;">
                                                        <strong>Assigned:</strong> {{ compartment.rowsAssigned }}
                                                        <br>
                                                        <strong>Available:</strong> <span :style="rowsAvailableModal < 0 ? isNegative : ''">{{ rowsAvailableModal }} <font-awesome-icon v-if="rowsAvailableModal < 0" icon="exclamation-triangle"></font-awesome-icon></span>
                                                        <br>
                                                        <strong>To assign:</strong> {{ rowsToAssignModal}}
                                                    </b-td>
                                                    <b-td style="width: 100%; vertical-align: middle;">
                                                        <p :style="rowsAvailableModal  < 0 ? isNegative : ''" style="font-size: 24px; margin-bottom: 0 !important;" class="text-center">{{ occupancyPercentModal != null ?
                                                        Number(occupancyPercentModal).toFixed(2) : 0 }} % <font-awesome-icon v-if="rowsAvailableModal < 0" icon="exclamation-triangle"></font-awesome-icon></p>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <td class="text-center">
                                                </td>
                                                <td style="background-color: #E5E7E9; width: 15%;">
                                                    <input class="form-control" placeholder="Production line" v-model="varietyNameFilterInput">
                                                </td>
                                                <td style="background-color: #E5E7E9">
                                                    <input class="form-control" placeholder="Crop" v-model="cropFilterInput">
                                                </td>
                                                <td style="background-color: #E5E7E9">
                                                    <input class="form-control" placeholder="Year" v-model="yearFilterInput">
                                                </td>
                                                <td>
                                                    <input class="form-control" placeholder="Season" v-model="seasonFilterInput">
                                                </td>
                                                <td style="background-color: #E5E7E9">
                                                    <input class="form-control" placeholder="Review" v-model="reviewFilterInput">
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th class="text-center">
                                                    <label class="checkbox-container d-inline-block">
                                                        <input type="checkbox" ref="checkboxAll" v-model="isAllcheck" @change="allCheck">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </th>
                                                <th style="width: 11%">Production line</th>
                                                <th style="width: 15%">Crop</th>
                                                <th style="width: 8%">Year</th>
                                                <th style="width: 8%">Season</th>
                                                <th style="width: 8%">Review</th>
                                                <th>Female parent</th>
                                                <th>Male parent</th>
                                                <th>Order kg</th>
                                                <th>Yield NL (g)</th>
                                                <th>Rows required</th>
                                            </tr>
                                        </thead>
                                            <tbody>
                                                <tr v-for="(item, key) in itemProductionLinesSlice" :key="key">
                                                    <td class="text-center">
                                                        <div v-if="item.rowsRequired > 0">
                                                            <label class="checkbox-container d-inline-block">
                                                                <input type="checkbox" :id="'checkbox-' + item.id" ref="checkbox" v-model="item.isSelected"  @change="calculateRows(item)" :disabled="item.rowsRequired === 0">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div v-else v-b-tooltip.hover title="Unable to assign production lines with 0 Rows required">
                                                            <label class="checkbox-container d-inline-block">
                                                                <input type="checkbox" :id="'checkbox-' + item.id" ref="checkbox" v-model="item.isSelected"  @change="calculateRows(item)" disabled>
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span class="label label-primary ml-1" style="width: 10px" v-b-tooltip.hover :title="item.processStatus">
                                                            {{ item.processStatus.charAt(0).toUpperCase() }}
                                                        </span>
                                                        {{ item.varietyName }}{{ item.tag ? '-'+item.tag : ''}}

                                                    </td>
                                                    <td> {{ item.crop }} </td>
                                                    <td> {{ item.orderYear }}</td>
                                                    <td> {{ item.season }} </td>
                                                    <td> {{ item.sequence }} </td>
                                                    <td> {{ item.parentalFemaleName }} </td>
                                                    <td> {{ item.parentalMaleName }} </td>
                                                    <td> {{ item.orderKg }} </td>
                                                    <td> {{ item.yieldNl }} </td>
                                                    <td :style=" item.rowsRequired > 0 ? '' :'color: red'"> {{ item.rowsRequired }} </td>
                                                </tr>
                                                <tr v-if="itemProductionLinesSlice.length <= 0" role="row" class="b-table-empty-row">
                                                    <td colspan="15" role="cell" class="">
                                                        <div role="alert" aria-live="polite">
                                                            <div class="text-center my-2">No records</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colspan="11" style="background-color: #F5F5F6; border-top: 1px solid #e7eaec;">
                                                <div class="row no-gutters">
                                                    <div class="col-sm-5 col-md-6">
                                                        <span class="pull-left m-t-xs" v-if="itemProductionLineMatched.length > 0">Show {{ startRecord }} to {{ endRecord }} of {{ totalRecords }} Records</span>
                                                        <span class="justify-content-center" v-else>No records</span>
                                                    </div>
                                                    <div class="my-1 col-sm-7 col-md-6 ">
                                                        <ul class="pagination mb-0 b-pagination justify-content-end">
                                                            <li class="page-item" @click="changePage('LEFT')">
                                                                <button class="page-link" role="menuitem" type="button" tabindex="-1" aria-label="Go to previous page">‹</button>
                                                            </li>
                                                            <li :class="['page-item', { active: page === 0 }]"
                                                                @click="changePage(0)">
                                                                <button @click="changePage(0)" role="menuitemradio" type="button" class="page-link">1</button>
                                                            </li>
                                                            <li class="page-item disabled" v-show="page - 3 > 0 && totalPages > 5">
                                                                <a>...</a>
                                                            </li>
                                                            <li :class="['page-item', { active: page === (index - 1) }]"
                                                                v-for="(index, key) in totalPages" v-show="shouldBeVisible(index)" :key="key">
                                                                <button @click="changePage(index - 1)" role="menuitemradio" class="page-link">{{ index }}</button>
                                                            </li>
                                                            <li class="page-item disabled" v-show="page + 3 < totalPages && totalPages > 5">
                                                                <a>...</a>
                                                            </li>
                                                            <li :class="['page-item', { active: page === (totalPages - 1) }]"
                                                                @click="changePage('MAX')" v-show="totalPages > 1">
                                                                <button @click="changePage(totalPages - 1)" role="menuitemradio" class="page-link">{{ totalPages }}</button>
                                                            </li>
                                                            <li class="page-item" @click="changePage('RIGHT')">
                                                                <button class="page-link" role="menuitem" type="button" tabindex="-1" aria-label="Go to next page">›</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                               <template #modal-footer>
                                   <div class="text-right">
                                       <b-button @click="$bvModal.hide('production-line-modal')" class="mr-2" variant="outline-secondary"> Cancel</b-button>
                                       <b-button @click="assignProductionLines" variant="primary" v-if="hasCreatePermission"><font-awesome-icon icon="link"></font-awesome-icon> Assign</b-button>
                                   </div>
                               </template>
                            </b-modal>
                        </div>
                    </div>
                </div>
            </template>
            <template>
                <b-row class="mt-2">
                    <b-col class="col-md-4 col-lg-4">
                        <b-table-simple ref="compartmentTable" responsive bordered striped>
                            <b-tbody>
                                <b-tr>
                                    <b-th style="width: 50%">Compartment</b-th>
                                    <b-td>{{ compartment.compartment }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width: 50%">Greenhouse</b-th>
                                    <b-td>{{ compartment.surfaceName }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width: 50%">Compartment net surface</b-th>
                                    <b-td>{{ compartment.netSurface }} mts²</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Rows</b-th>
                                    <b-td>{{ compartment.numberOfRows }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Row net surface</b-th>
                                    <b-td>{{ Number(compartment.rowSurface).toFixed(2) }} mts²</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Rows assigned</b-th>
                                    <b-td>{{ !compartment.rowsAssigned ? 0 : compartment.rowsAssigned }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Rows available
                                        <font-awesome-icon v-if="compartment.rowsAvailable < 0" icon="exclamation-triangle"></font-awesome-icon></b-th>
                                    <b-td :style="compartment.rowsAvailable < 0 ? isNegative : ''">{{ compartment.rowsAvailable }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Occupancy rate
                                        <font-awesome-icon v-if="compartment.rowsAvailable < 0" icon="exclamation-triangle"></font-awesome-icon></b-th>
                                    <b-td :style="compartment.rowsAvailable < 0 ? isNegative : ''">{{ compartment.occupancyPercent != null ?
                                        Number(compartment.occupancyPercent).toFixed(2) : 0 }} %</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                    <b-col class="col-md-4 col-lg-4">
                        <Pie v-if="chartData.datasets[0].data.length > 0"
                            :chart-options="chartOptions"
                            :chart-data="chartData"
                            style="height: 180px;"/>
                    </b-col>
                    <b-col class="col-md-4 col-lg-4"></b-col>
                </b-row>
            </template>

            <b-row class="mb-2">
                <b-col sm="5" md="6" class="my-1">
                    <b-input-group size="sm" prepend="Show" append="records">
                        <b-form-select v-model="productionLinesOccupancy.itemsPerPage" id="per-page-select" size="sm" :options="productionLinesOccupancy.pageOptions" style="max-width: 5em"></b-form-select>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-table :items="loadData" v-if="occupancyId != null && hasListPermission" :fields="filteredFields" :filter="productionLinesOccupancy.filter" :current-page="productionLinesOccupancy.currentPage"
                     :per-page="productionLinesOccupancy.itemsPerPage" empty-text="No records" striped bordered hover small show-empty :responsive="true"
                     :sort-by="productionLinesOccupancy.sortBy" :sort-desc="productionLinesOccupancy.sortDesc" ref="productionLinesOccupancyTable" class="text-left mb-0" @context-changed="tableContextChanged">

                <template #thead-top>
                    <b-tr class="table-filters">
                        <b-th v-for="(field, index) in filteredFields" :key="field.key" :class="productionLinesOccupancy.filters[index] && productionLinesOccupancy.filters[index].value ? 'non-empty' : ''">
                            <b-row v-if="productionLinesOccupancy.filters[index]">
                                <b-col v-if="productionLinesOccupancy.filters[index].type === 'STRING'">
                                    <b-input :placeholder="field.label" v-model="productionLinesOccupancy.filters[index].value" debounce="300"></b-input>
                                </b-col>
                            </b-row>
                        </b-th>
                    </b-tr>
                </template>

                <template #cell(varietyName)="row">
                    <div class="text-center">
                        <p>
                             <span class="label label-primary mr-2" style="width: 10px" v-b-tooltip.hover :title="row.item.processStatus">
                                {{ row.item.processStatus.charAt(0).toUpperCase() }}
                            </span>
                            <router-link v-if="hasProductionLineListPermission" :to="{ name: 'ProductionLineShow', params: { id: row.item.id, variety: row.item.varietyName, tag: row.item.tag }}" target="_blank">{{ row.item.varietyName }}{{row.item.tag ? '-'+row.item.tag : ''}}</router-link>
                            <span v-else>{{ row.item.varietyName }}{{row.item.tag ? '-'+row.item.tag : ''}}</span>
                        </p>
                    </div>
                </template>

                <template #table-caption>
                    <b-row class="no-gutters">
                        <b-col sm="5" md="6">
                            Show {{ start }} to {{ end }} of {{ productionLinesOccupancy.totalRows }} record{{ productionLinesOccupancy.totalRows !== 1 ? 's' : '' }}
                        </b-col>
                        <b-col sm="7" md="6" class="my-1">
                            <b-pagination v-model="productionLinesOccupancy.currentPage" :total-rows="productionLinesOccupancy.totalRows" :per-page="productionLinesOccupancy.itemsPerPage" align="right" class="mb-0"></b-pagination>
                        </b-col>
                    </b-row>
                </template>

                <template #cell(actions)="row" v-if="hasCreatePermission || hasProductionLineListPermission">
                    <b-button-group size="sm">
                        <b-button size="sm" :to="{ name: 'ProductionLineShow', params: { id: row.item.id } }" v-b-tooltip.hover target="_blank"
                                  title="Detail" variant="outline-primary" v-if="hasProductionLineListPermission">
                            <font-awesome-icon icon="search"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" @click="unassignProductionLine(row.item.id)" v-b-tooltip.hover v-if="hasCreatePermission"
                                  title="Unassign" variant="outline-primary">
                            <font-awesome-icon icon="unlink"></font-awesome-icon>
                        </b-button>
                    </b-button-group>
                </template>
            </b-table>
        </b-card>
    </b-overlay>
</template>
<script>
import { extend } from 'vee-validate';
import { debounce } from 'debounce';
import {
    compartmentOccupancyAssign,
    compartmentOccupancyListUrl,
    compartmentProductionLines,
    productionLinesOccupancyListUrl,
    unassignedProductionLine
} from '@routes';
import tableStateMixin from '../../mixins/tableState';
import { required } from 'vee-validate/dist/rules';
import { Pie } from 'vue-chartjs';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js';
import $permission from '@/js/permission';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

extend('required', {
    ...required,
    message: 'Field required'
});
export default {
    mixins: [tableStateMixin],
    components: {
        Pie
    },
    data() {
        return {
            productionLinesOccupancy: {
                fields: [
                    { key: 'cropFamilyName', label: 'Crop', sortable: true, class: 'text-center' },
                    { key: 'varietyName', label: 'Production line', sortable: true,  class: 'text-center' },
                    { key: 'assignmentDate', label: 'Assignment date', sortable: true,  class: 'text-center' },
                    { key: 'parentalFemaleName', label: 'Female parent', sortable: true, class: 'text-center' },
                    { key: 'parentalMaleName', label: 'Male parent', sortable: true, class: 'text-center' },
                    { key: 'orderId', label: 'Order', sortable: true, class: 'text-center' },
                    { key: 'orderDate', label: 'Order date', sortable: true, class: 'text-center' },
                    { key: 'orderKg', label: 'Order kg', sortable: true, class: 'text-center' },
                    { key: 'yieldNl', label: 'Yield NL', sortable: true, class: 'text-center' },
                    { key: 'rowsRequired', label: 'Rows required', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Actions', sortable: false, class: 'text-center' }
                ],
                fieldsList: [
                    { key: 'cropFamilyName', label: 'Crop', sortable: true, class: 'text-center' },
                    { key: 'varietyName', label: 'Production line', sortable: true,  class: 'text-center' },
                    { key: 'assignmentDate', label: 'Assignment date', sortable: true,  class: 'text-center' },
                    { key: 'parentalFemaleName', label: 'Female parent', sortable: true, class: 'text-center' },
                    { key: 'parentalMaleName', label: 'Male parent', sortable: true, class: 'text-center' },
                    { key: 'orderId', label: 'Order', sortable: true, class: 'text-center' },
                    { key: 'orderDate', label: 'Order date', sortable: true, class: 'text-center' },
                    { key: 'orderKg', label: 'Order kg', sortable: true, class: 'text-center' },
                    { key: 'yieldNl', label: 'Yield NL', sortable: true, class: 'text-center' },
                    { key: 'rowsRequired', label: 'Rows required', sortable: true, class: 'text-center' }
                ],
                filters: [
                    { column: 'crop', type: 'STRING', value: '', format: '' },
                    { column: 'varietyName', type: 'STRING', value: '', format: '' }
                ],
                filter: '',
                pageOptions: [10, 25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 10,
                sortBy: 'cropFamilyName',
                sortDesc: false
            },
            breadcrumb: {
                title: '',
                path: []
            },
            isLoading: false,
            compartment: {},
            occupancy: [],
            toastTitle: '',
            itemProductionLines: [],
            varietyNameFilterInput: '',
            cropFilterInput: '',
            yearFilterInput: '',
            seasonFilterInput: '',
            reviewFilterInput: '',
            multipleFilter: {
                varietyName: '',
                crop: '',
                orderYear: '',
                season: '',
                sequence: ''
            },
            page: 0,
            itemsPerPageProductionLine: 25,
            filter: '',
            filterInput: '',
            itemProductionLineMatched: [],
            occupancyId: null,
            rowsAvailableModal: null,
            rowsAssignedModal: 0,
            rowsToAssignModal: 0,
            occupancyPercentModal: null,
            fontWeight: 600,
            color: '#f10606',
            isAllcheck: false,
            chartData: {
                labels: ['Occupancy rate', 'Vacancy rate'],
                datasets: [
                    {
                        backgroundColor: function(context) {
                            if (context.dataset.data[1] < 0) {
                                return ['red', 'red'];
                            } else {
                                return ['#97bf0d', '#CCCCCCF2'];
                            }
                        },
                        data: []
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: context => {
                                const label = context.label || '';
                                const value = context.parsed || 0;
                                return label + ': ' + value + '%';
                            }
                        }
                    }
                }
            },
            hasCreatePermission: $permission.check('ROLE_COMP_PRAS_C'),
            hasListPermission: $permission.check('ROLE_COMP_PRAS_L'),
            hasProductionLineListPermission: $permission.check('ROLE_PRLI_L')
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.loadDataCompartment();
        this.restoreTableState(this.productionLinesOccupancy, this.tableState);
    },
    methods: {
        loadData(productionLine) {
            if (productionLine.sortBy !== '') {
                let data = {
                    filters: this.productionLinesOccupancy.filters.filter(filter => filter.value.trim() !== ''),
                    ...productionLine
                };
                data.currentPage = (data.currentPage - 1) * data.perPage;
                data.occupancyId = this.occupancyId;

                return this.axios.post(productionLinesOccupancyListUrl(), data).then(response => {
                    if (response.status === 200) {
                        this.productionLinesOccupancy.totalRows = response.data.totalRows;
                        return response.data.productionLines;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    if (error.response) {
                        this.$bvToast.toast(error.response.data.message, {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else if (error.request) {
                        this.$bvToast.toast('An error occurred while executing the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else {
                        this.$bvToast.toast('An error occurred while configuring the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }
                });
            }

        },
        loadDataCompartment() {
            this.axios.get(compartmentOccupancyListUrl(this.$route.params.id)).then(response => {
                if (response.status === 200) {
                    this.compartment = response.data.compartment;
                    this.breadcrumb.title = `Compartment ${this.compartment.compartment}`;
                    this.toastTitle = `Compartment ${this.compartment.compartment}`;
                    this.occupancyId = response.data.compartment.occupancyId ? response.data.compartment.occupancyId : 0;
                    this.chartData.datasets[0].data = [ response.data.compartment.occupancyPercent, Number(100 - response.data.compartment.occupancyPercent).toFixed(2) ];
                    if (this.occupancyId) {
                        if (this.$refs.productionLinesOccupancyTable) {
                            this.$refs.productionLinesOccupancyTable.refresh();
                            this.saveTableState(this.$route.name, this.productionLinesOccupancy);
                        }
                    }
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request 123312', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            });
        },
        loadDataProductionLines() {
            this.isLoading = true;
            this.axios.get(compartmentProductionLines()).then(response => {
                if (response.status === 200) {
                    this.itemProductionLines = response.data.productionLines;
                    this.showModalProductionLines();
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        showModalProductionLines() {
            this.itemProductionLines.forEach(value => {
                value.isSelected = false;
            });
            this.isAllcheck = false;
            this.rowsAvailableModal = this.compartment.rowsAvailable;
            this.rowsAssignedModal = this.compartment.rowsAssigned;
            this.rowsToAssignModal = 0;
            this.occupancyPercentModal = this.compartment.occupancyPercent;
            this.varietyNameFilterInput = '';
            this.cropFilterInput = '';
            this.yearFilterInput = '';
            this.seasonFilterInput = '';
            this.reviewFilterInput = '';

            this.$bvModal.show('production-line-modal');

            this.itemProductionLineMatched = this.itemProductionLines;
        },
        assignProductionLines() {
            if (!this.itemProductionLines.some(value => value.isSelected)) {
                this.$bvToast.toast('Select production line to assign', {
                    title: this.toastTitle,
                    variant: 'warning'
                });
                return false;
            }
            let liFolio = '';
            let itemsSelected = this.itemProductionLines.filter(value => value.isSelected);
            itemsSelected.forEach(value => {
                let varietyName = '';

                if (value.varietyName) {
                    varietyName += value.varietyName;

                    if (value.tag) {
                        varietyName += '-' + value.tag;
                    }
                }
                liFolio += '<li style="display: inline-block; margin-right: 10px;">' + varietyName + '</li>';
            });

            if (this.rowsAvailableModal < 0 || this.occupancyPercentModal > 100) {
                this.$swal({
                    title: 'There are not enough rows available in the compartment',
                    icon: 'warning',
                    confirmButtonColor: '#97bf0d',
                    showCancelButton: false,
                    confirmButtonText: 'OK'
                });
            } else {
                this.$swal({
                    title: `Are you sure to assign production lines to compartment ${this.compartment.compartment}?`,
                    html: `These production lines will be assigned:<br><ul style="list-style: none; padding: 0;"><strong>${liFolio}</strong></ul>`,
                    icon: 'warning',
                    cancelButtonText: 'Cancel',
                    confirmButtonColor: '#97bf0d',
                    confirmButtonText: 'Yes'
                }).then(result => {
                    if (result.value) {
                        if (this.occupancyPercentModal > 100) {
                            this.$bvToast.toast('There are not enough rows available in the compartment', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.axios.post(compartmentOccupancyAssign(), {
                                compartmentId: this.compartment.id,
                                occupancyId: this.occupancyId,
                                occupancyPercent: (this.rowsAssignedModal * 100) / this.compartment.numberOfRows,
                                rowsAvailableModal: this.rowsAvailableModal,
                                rowsAssignedModal: this.rowsAssignedModal,
                                productionLines: itemsSelected
                            }).then(response => {
                                if (response.status === 200) {
                                    //TODO Traer en el response el rows_assigned
                                    this.compartment.rowsAssigned = this.rowsAssignedModal;
                                    this.compartment.rowsAvailable = this.rowsAvailableModal;
                                    this.$bvModal.hide('production-line-modal');
                                    this.$bvToast.toast(response.data.message, {
                                        title: this.toastTitle,
                                        variant: 'success'
                                    });
                                    this.loadDataCompartment();
                                } else {
                                    this.$bvToast.toast(response.data.message, {
                                        title: this.toastTitle,
                                        variant: 'danger'
                                    });
                                }
                            }).catch(error => {
                                if (error.response) {
                                    this.$bvToast.toast(error.response.data.message, {
                                        title: this.toastTitle,
                                        variant: 'danger'
                                    });
                                } else if (error.request) {
                                    this.$bvToast.toast('An error occurred while executing the request', {
                                        title: this.toastTitle,
                                        variant: 'danger'
                                    });
                                } else {
                                    this.$bvToast.toast('An error occurred while configuring the request', {
                                        title: this.toastTitle,
                                        variant: 'danger'
                                    });
                                }
                            });
                        }
                    }
                });
            }
        },
        unassignProductionLine(productionLineId) {
            this.$swal({
                title: 'Are you sure to unassign the production line?',
                icon: 'warning',
                iconColor: '#bf0d0d',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(unassignedProductionLine(), {
                        id: productionLineId,
                        rowsAssigned: this.compartment.rowsAvailable
                    }).then(response => {
                        if (response.data.code === 'OK') {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                            this.$refs.productionLinesOccupancyTable.refresh();
                            this.loadDataCompartment();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    });
                }
            });
        },
        calculateRows(item) {
            if (item.rowsRequired > this.rowsAvailableModal && item.isSelected) {
                this.$bvToast.toast('There are no enough rows available in compartment', {
                    title: this.toastTitle,
                    variant: 'danger'
                });
            }

            if (item.isSelected) {
                this.rowsAssignedModal += item.rowsRequired;
                this.rowsAvailableModal -= item.rowsRequired;
                this.rowsToAssignModal += item.rowsRequired;
                this.occupancyPercentModal = (this.rowsAssignedModal * 100) / this.compartment.numberOfRows;
                this.isAllcheck = this.itemProductionLineMatched.every(item => item.isSelected);
            } else {
                this.rowsAvailableModal += item.rowsRequired;
                this.rowsAssignedModal -= item.rowsRequired;
                this.rowsToAssignModal -= item.rowsRequired;
                this.occupancyPercentModal = ((this.rowsAssignedModal * 100) / this.compartment.numberOfRows);
                this.isAllcheck = false;
            }
        },
        allCheck() {
            let isNotAvailableRows = false;

            this.itemProductionLineMatched.forEach(value => {
                if (!value.isSelected && this.isAllcheck) {
                    isNotAvailableRows = value.rowsRequired > this.rowsAvailableModal;
                    this.rowsAssignedModal += value.rowsRequired;
                    this.rowsAvailableModal -= value.rowsRequired;
                    this.rowsToAssignModal += value.rowsRequired;
                    this.occupancyPercentModal = (this.rowsAssignedModal * 100) /this.compartment.numberOfRows;
                } else if (value.isSelected && !this.isAllcheck) {
                    this.rowsAvailableModal += value.rowsRequired;
                    this.rowsAssignedModal -= value.rowsRequired;
                    this.rowsToAssignModal -= value.rowsRequired;
                    this.occupancyPercentModal = ((this.rowsAssignedModal * 100) / this.compartment.numberOfRows);
                }

                value.isSelected = this.isAllcheck;
            });

            if (isNotAvailableRows) {
                this.$bvToast.toast('There are no enough rows available in compartment', {
                    title: this.toastTitle,
                    variant: 'danger'
                });
            }
        },
        shouldBeVisible(index) {
            let visible;

            if (index === 1 || index === this.totalPages) {
                visible = false;
            } else if ((this.page < 4 && index <= 5) || (this.page > (this.totalPages - 4) && index >= (this.totalPages - 4))) {
                visible = true;
            } else {
                visible = Math.abs(this.page - (index - 1)) < 2;
            }

            return visible;
        },
        normalizeText(text) {
            if (text) {
                return text.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u0301]/g, '').normalize();
            }
            return '';
        },
        itemProductionLineFilterMultiple(itemProductLine) {
            return (
                (this.multipleFilter.varietyName !== '' ? this.normalizeText(itemProductLine.varietyName).includes(this.normalizeText(this.multipleFilter.varietyName)) : true) &&
                (this.multipleFilter.crop !== '' ? this.normalizeText(itemProductLine.crop).includes(this.normalizeText(this.multipleFilter.crop)) : true) &&
                (this.multipleFilter.orderYear !== '' ? this.normalizeText(itemProductLine.orderYear).includes(this.normalizeText(this.multipleFilter.orderYear)) : true) &&
                (this.multipleFilter.season !== '' ? this.normalizeText(itemProductLine.season).includes(this.normalizeText(this.multipleFilter.season)) : true) &&
                (this.multipleFilter.sequence !== '' ? this.normalizeText(itemProductLine.sequence).includes(this.normalizeText(this.multipleFilter.sequence)) : true));
        },
        changePage(order) {
            /* this.itemProductionLineMatched = [];
            this.itemProductionLineMatched = this.itemProductionLines;*/

            this.$nextTick(() => {
                if (!isNaN(order)) {
                    this.page = Math.max(order, 0);
                } else if (order === 'MAX') {
                    this.page = Math.max(this.totalPages - 1, 0);
                } else if (order === 'LEFT' && this.page > 0) {
                    this.page--;
                } else if (order === 'RIGHT' && this.page < (this.totalPages - 1)) {
                    this.page++;
                }
            });
        },
        tableContextChanged(context) {
            this.productionLinesOccupancy.sortBy = context.sortBy;
            this.productionLinesOccupancy.sortDesc = context.sortDesc;
            this.saveTableState(this.$route.name, this.productionLinesOccupancy);
        }
    },
    watch: {
        // PAGINATION
        filterInput: debounce(function() {
            this.filter = this.filterInput;
        }),
        filter() {
            this.itemProductionLineMatched = this.itemProductionLines.filter(this.itemProductionLineFilterMultiple);
            this.changePage(0);
        },
        itemsPerPageProductionLine() {
            this.changePage(0);
        },
        varietyNameFilterInput: debounce(function() {
            this.multipleFilter.varietyName = this.varietyNameFilterInput;
        }),
        cropFilterInput: debounce(function() {
            this.multipleFilter.crop = this.cropFilterInput;
        }),
        yearFilterInput: debounce(function() {
            this.multipleFilter.orderYear = this.yearFilterInput;
        }),
        seasonFilterInput: debounce(function() {
            this.multipleFilter.season = this.seasonFilterInput;
        }),
        reviewFilterInput: debounce(function() {
            this.multipleFilter.sequence = this.reviewFilterInput;
        }),
        multipleFilter: {
            handler() {
                this.itemProductionLineMatched = this.itemProductionLines.filter(this.itemProductionLineFilterMultiple);
                this.changePage(0);
            },
            deep: true
        },
        'productionLinesOccupancy.filters': {
            handler() {
                this.$refs.productionLinesOccupancyTable.refresh();
                this.saveTableState(this.$route.name, this.productionLinesOccupancy);
            },
            deep: true
        }
    },
    computed: {
        start() {
            return this.productionLinesOccupancy.totalRows === 0 ? 0 : (this.productionLinesOccupancy.currentPage - 1) * this.productionLinesOccupancy.itemsPerPage + 1;
        },
        end() {
            let offset = (this.productionLinesOccupancy.currentPage - 1) * this.productionLinesOccupancy.itemsPerPage;

            return (this.productionLinesOccupancy.totalRows - offset) < this.productionLinesOccupancy.itemsPerPage ? this.productionLinesOccupancy.totalRows : offset + this.productionLinesOccupancy.itemsPerPage;
        },
        itemProductionLinesSlice() {
            const start = this.page * this.itemsPerPageProductionLine;
            const end = start + this.itemsPerPageProductionLine;

            return this.itemProductionLineMatched.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.itemProductionLineMatched.length / this.itemsPerPageProductionLine);
        },
        startRecord() {
            return this.page * this.itemsPerPageProductionLine + 1;
        },
        endRecord() {
            let offset = this.page * this.itemsPerPageProductionLine;

            return (this.itemProductionLineMatched.length - offset) < this.itemsPerPageProductionLine ?
                this.itemProductionLineMatched.length : offset + this.itemsPerPageProductionLine;
        },
        totalRecords() {
            return this.itemProductionLineMatched.length;
        },
        isNegative() {
            return {
                fontWeight: this.fontWeight,
                color: this.color
            };
        },
        filteredFields() {
            if (this.hasCreatePermission || this.hasProductionLineListPermission) {
                return this.productionLinesOccupancy.fields;
            } else {
                return this.productionLinesOccupancy.fieldsList;
            }
        }
    }
};
</script>